import React from 'react'
import { Link } from 'gatsby'
import { FaAngleLeft } from "react-icons/fa";

//Styles
import { Wrapper } from './HeaderRest.styles'

class Header extends React.Component{

    componentDidMount() {
        var scrollpos = window.scrollY;
        var header = document.querySelector(".head");
        console.log(header) 

        function add_class_on_scroll() {
            header.classList.add("header-bg");
        }

        function remove_class_on_scroll() {
            header.classList.remove("header-bg");
        }

        window.addEventListener('scroll', function(){ 
            //Here you forgot to update the value
            scrollpos = window.scrollY;

            if(scrollpos > 10){
                add_class_on_scroll();
            }
            else {
                remove_class_on_scroll();
            }
        });
    }
    render(){
        return(
            <>
            <Wrapper className="head">  
                <Link class="back-btn" to="/"><FaAngleLeft /></Link>        
                <Link to="/"><span>R</span>estauranger <span>B</span>orås
                </Link>
            </Wrapper>
            </>
        )
    }   
}

export default Header