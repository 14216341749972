import React from "react"
import { graphql, Link } from "gatsby"
// Components
import Layout from "components/Layout"
import Img from "gatsby-image"


import SEO from "components/SEO"
import { FaHome, FaMapMarkerAlt, FaPhoneSquareAlt } from 'react-icons/fa';
import HeaderRestaurant from "../components/HeaderRestaurant"
import Footer from "components/Footer"

// Styles
import { RestStyles } from 'styles/RestStyles'
// Hooks
import { useFrontcardsDataQuery } from 'Hooks/useFrontcardsDataQuery'


const Page = ({ data }) => {
  const page = data.markdownRemark 
  const frontcardQuery = useFrontcardsDataQuery()

  return (
    <Layout> 
      <RestStyles />
      <HeaderRestaurant />
      <SEO title={page.frontmatter.seotitle} description={page.frontmatter.seodescription}/>
      <Img width="1600" height="400" className="rest-page-hero-img" fluid={page.frontmatter.imgdesk.childImageSharp.fluid} alt={page.frontmatter.title}/>
      <main className="rest-page">
        <div className="black-holder">
        <p>Restauranger<span>Boras.se</span></p>
        <h1>{page.frontmatter.h1}</h1>
        <div className="time">
          {frontcardQuery.frontcards.map(item => ( 
            <>          
              {page.frontmatter.title === item.title &&
                <>   
                  <div className="mon-fre">
                      <p>{item.otidrad1left} </p>
                      <span>{item.otidrad1right}</span>
                  </div>
                  <div className="lordag">
                      <p>{ item.otidrad2left }</p>
                      <span>{ item.otidrad2right }</span>
                  </div>
                  <div className="son">
                      <p>{ item.otidrad3left }</p>
                      <span>{ item.otidrad3right }</span>
                  </div>
                  {item.otidrad4left &&
                      <>
                          <div className="son">
                              <p>{ item.otidrad4left }</p>
                              <span>{ item.otidrad4right }</span>
                          </div>
                      </>
                  }
                  <p>Lunchpris: {item.lunch} kr</p>
                </>
              }
            </>
          ))}

         
        </div>
        <div className="rest-content">
          <h2>{page.frontmatter.h2}</h2>
          <div dangerouslySetInnerHTML={{ __html: page.html }} /> 
        </div>
        </div>
      </main>
      <Footer />
      <div className="footer-rest"> 
        <Link to='/'><FaHome /></Link>
        <a href={page.frontmatter.map} title="mappin" target="_blank" rel="noreferrer nofollow">< FaMapMarkerAlt/></a>
        <a href={page.frontmatter.tel} title="phone" ><FaPhoneSquareAlt /></a>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html 
      frontmatter { 
        title
        h1
        h2
        tel
        address
        map
        hemsida
        matsort
        slug
        seotitle
        seodescription
        imgdesk {
          childImageSharp {
            fluid(maxWidth:1600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      }
    }
  }
`