import styled from "styled-components"

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1600px;
  width:100%;
  height: auto;
  margin: 0px auto;
  overflow: visible;
  z-index: 999;
  position: fixed;
  top:0px;
  max-width:1600px;
  margin:0 auto;
  background: #222629 !important;
  background: transparent;
  padding-top: 15px;
  padding-bottom:15px;
  transition:.3s;

  &.head {
    position: relative;
    display: flex;
    justify-content: center;

    .back-btn {
      position: absolute;
      left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      padding: 0;
      background: ${props => props.theme.colors.prime};
      width: 30px;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      transition: .3s;

      @media screen and (min-width: 768px) {
        left: 50px;
      }

      svg {
        font-size: 1.5rem;
      }

      &:hover {
        background: black;
      }

    }
  }

  @media screen and (min-width: 768px) {
    padding: 0;
  }

  a {
    color:white;
    font-weight: 100;
    font-size: 1.1rem;
    letter-spacing: 2px;

    @media screen and (min-width: 768px) {
      font-size: 2rem;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    span {
      font-size: 1.3rem;
      color: ${props => props.theme.colors.prime};

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        }
    }
  }
`

export const Logo = styled.img`
  max-width: 100px;
  max-height: 29px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    
  }
`
