import { createGlobalStyle } from "styled-components"

export const RestStyles = createGlobalStyle` 
.rest-page-hero-img {
    height:40vh;
    width:100%;
    border-radius:0;
    position:relative;
    @media screen and (min-width: 768px) {
      height:40vh;
    }

    @media screen and (min-width: 1200px) {
      height:40vh;
      max-height: 400px;
    }
  
    :before {
      content:"";
      height:100%;
      position:absolute;
      top:0;
      width:100%;
      background:rgba(0,0,0,.0);
      z-index:1;
    }
    @media screen and (min-width: 768px) {
      .page-hero-img {
        height:60vh;
      }
    }
  }

.footer-rest {
  display: flex;
  justify-content: space-around;
  background: ${props => props.theme.colors.lightgray};
  position: fixed;
  bottom:0;
  width:100%;
  z-index: 999;

  @media screen and (min-width: 768px) {
    display: none;
  }

  a {
    color: white;
    font-size:1.75rem;
    margin:0;
    padding-top:7px;
    padding-bottom:7px;
    display:flex;

    &:first-child {
      color: ${props => props.theme.colors.prime};
  }
  }
}


.rest-page {
  background: ${props => props.theme.colors.darkgray};
  padding-bottom:100px;
  p {
    text-transform: uppercase;
    color: white;
    margin:0;
    padding:15px;
    font-size:.7rem;
    span {
      font-size:.7rem;
      color: ${props => props.theme.colors.prime};
    }
  }
  h1 {
    position: relative;
    color: white;
    text-align: left;
    width: calc(100% - 15px);        font-size: 2rem;
    z-index:3;
    margin:0;
    padding-left:15px;
    font-weight:200;

    @media screen and (min-width: 768px) {
      width: 50%;
      left: 25%;
     }
  } 
  .time {
    margin-top:10px;
    margin-bottom:35px;
    div {
      display:flex;
      p {
        padding-top: 5px;
        padding-bottom:0;
        width:25%;
      }
      span {
        color: ${props => props.theme.colors.prime};
        text-transform: uppercase;
        font-size:.7rem;
        padding-top: 5px;
        padding-bottom: 0;
      }
    }
  }
  .rest-content {
    color: white;
    h2 {
      padding-left:15px;
      margin-bottom:0;
    }
    p {
      text-transform: none;
      font-size: 1rem;
      letter-spacing: 2px;
      line-height: 22px;
      font-weight: 200;
    }
  }
}

.rest-page {
  padding: 10px;
  background: black;

  .black-holder {
    background: #272d2d;
    border-radius: 30px;
    margin-top: 35px;
    margin-bottom: 70px;

    @media screen and (min-width: 768px) {
    margin:0;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 35px;
    p {
      padding-right: 0;
      font-size: 1rem;
      width: 90%;
      left: 10%;
      position: relative;
      color: white; 

  
      span {
        font-size: 1rem;
      }
    } 

    h1 {
      width: 90%;
      left: 10%;
      font-size: 2.2rem;
    }

    .time {
      div { 
          span {
            font-size: 1rem;
          }
      }
    }
    .rest-content {
      h2 {
        width: 90%;
        left: 10%;
        position: relative;
        font-size: 1.3rem;
      }

      div {
        p {
          width: 80%;
          color: ${props => props.theme.colors.pcolor};
        }
      }
    }
  } 
  @media screen and (min-width: 1200px) {
    padding-top: 5%;

    h1 {
      font-size: 3rem;
    }

    .rest-content {
      h2 {
        font-size:2rem;
        font-weight: 300;
      }
    }
  }
}

`